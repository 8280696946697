.no-link-color a {
  color: #000;
}

.has-grey-border-bottom {
  border-bottom: 1px solid #eee;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
