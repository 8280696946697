.input,
.select,
.textarea,
.has-addons .button,
label.checkbox {
  font-size: 0.75rem;
  border-radius: 0;
}

.label {
  font-size: 14px;
}

select {
  width: 100%;
  height: auto !important;
  color: #333;
  padding: 7px 10px 6px;
  border: 1px solid #ddd;
}

.help {
  color: #999;
}

.input-error-message {
  font-size: 12px;
}

.unit {
  color: #333;
  font-size: 13px;
  padding-top: 6px;
  padding-left: 8px;
  display: inline-block;
}

.remove-box {
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: -5px;
  font-size: 13px;
  background-color: #333;
  color: #fff;
  border-radius: 50px;
  width: 21px;
  height: 21px;
  text-align: center;
  font-weight: bold;
  z-index: 10;
  padding-top: 1px;
}
