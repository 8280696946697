$primary: #974d39;
@import "../node_modules/bulma/bulma.sass";
@import "variables";
@import "margins";
@import "helpers";
@import "form";
@import "table-extension";

body {
  background: #eeeeee;
  font-family: "Archivo", sans-serif;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-left: 80px;
  padding-top: 22px;
  padding-right: 30px;

  @media #{$mobile} {
    padding: 80px 20px;
  }
}

.wrapper-image {
  @media #{$mobile} {
    padding: 50px;
  }
}

.navbar {
  background: #fff;
  height: 80px;
}

.footer {
  background: #fff;
}

.container {
  height: 100%;
}

.main {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.is-columns-vcentered {
  flex: 1;
}

h1 {
  font-size: 35px;
  line-height: 1.2;

  span {
    margin-left: 5px;
    font-size: 12px;
  }

  @media #{$mobile} {
    margin-bottom: 0;
  }
}

h2 {
  font-size: 30px;
  margin-bottom: 10px;

  span {
    margin-left: 5px;
    font-size: 12px;
  }

  @media #{$mobile} {
    font-size: 25px;
  }
}

h3 {
  font-size: 25px;
  margin-bottom: 10px;

  span {
    margin-left: 5px;
    font-size: 12px;
  }

  @media #{$mobile} {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

input.input {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 10px;
}

.callout {
  font-family: canelaLight;
  font-size: 70px;
  line-height: 1.2;
  color: #000;

  span {
    font-family: canelaLightItalic;
  }
}

.is-clickable {
  cursor: pointer;
  text-decoration: underline;
}

.is-clickable.no-decoration {
  cursor: pointer;
  text-decoration: none;
}

.box {
  border-radius: 0;
  box-shadow: none;
  padding: 20px;

  &:hover {
    background: #f7f7f7;
  }

  @media #{$mobile} {
    padding: 15px;
  }

  &.is-danger {
    background: $danger;
  }
}

.box-title {
  font-weight: 400;
  font-size: 12px;
  color: #777;
}

.box-sub-title {
  font-size: 14px;
  color: #111;
  font-weight: 500;
}

.tag.is-border {
  border: 1px solid #000;
  background: none;
}

.is-image-full-height {
  max-height: 100vh;
  object-fit: cover;
  width: 100%;
  display: block;
}

/*TAG*/

.box .tag {
  border-radius: 0;
  max-width: 130px;
  height: 30px;
}

/*FORM*/

.input {
  //background: none;
  border: 1px solid #bbb;
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.round-image {
  border-radius: 50px;
}

.divider {
  color: #999;
  margin: 0 10px;
  font-size: 12px;
}

.control.has-icons-left .icon.is-small {
  height: 40px;
  width: 40px;

  svg {
    height: 20px;
    width: 20px;
  }
}

// A new set of sizes for buttons to align them with tags and use inside tables.
.button.is-tiny {
  height: 1.5rem;
  font-size: 0.75rem;
  text-transform: none;
  letter-spacing: normal;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.select.is-tiny {
  height: 1.5rem;

  select {
    font-size: 0.75rem;
    height: 2em;
    line-height: 0.75rem;
  }

  &::after {
    right: 0.55rem !important;
    margin-top: -0.3rem !important;
  }
}

table {
  th,
  td {
    vertical-align: middle !important;
  }
}

.dropdown-item {
  cursor: pointer;
  border: none;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.accent-text {
  color: #974d39;
}
