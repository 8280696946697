.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-5 {
  margin-right: 5px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}
