// Most table styles are defined in Bulma, these are just some style extensions
// we need.

// Based on https://codepen.io/jdsteinbach/pen/RNqmYJ
.table.is-loading {
  tbody {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 3px solid grey;
      border-top-color: white;
      animation: loading 1s linear infinite;
      z-index: 2;
    }
  }
}

.table thead {
  background: white;
  position: sticky;
  top: 0;
  position: relative;
  z-index: 10;

  th {
    box-shadow: 0px 1px 0px #dbdbdb;
  }
}

.table tr.is-error {
  border-left: solid 3px red
}
